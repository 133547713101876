import "../Assets/Styles/Industries.css";
import { IMAGES } from "../utils";
const Industries = () => {
  return (
    <>
      <div className="industry_section">
        <div className="mb-4">
          <div className="indservices d-lg-flex d-md-block d-sm-block">
            <h3 className="heading-indservices col-lg-6 col-md-6 col-sm-12 ">
              Industries We Support
            </h3>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <p className="para-indservices ">
                We bring the breathe of our experience and industry knowledge to
                help you
                <br /> succeed. We work to understand your issues and are driven
                to ask better <br />
                questions in the pursuit of making work.
              </p>
            </div>
          </div>
        </div>
        <div className="industry_wrapper">
          <div className="row ">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="industry_cards">
                <img
                  src="https://motivoweb.com/saga/wp-content/uploads/2023/09/service22.jpg"
                  alt=""
                />
                <div className="perspective-caption">
                  <div className="icon-wrap">
                    {/* <img src={IMAGES.group} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      width="48"
                      x="0px"
                      y="0px"
                      viewBox="0 0 60 60"
                    >
                      {" "}
                      <g transform="translate(-1 -1)">
                        {" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M11.9,17L11.9,17c-2.8,0-5.1-2.3-5.1-5.1l0,0c0-2.8,2.3-5.1,5.1-5.1l0,0c2.8,0,5.1,2.3,5.1,5.1l0,0C17,14.7,14.7,17,11.9,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M15.7,55.2H8.1l-1.3-14L3,39.9V28.5c0-2.8,2.3-5.1,5.1-5.1h7.6c1.8,0,3.4,0.9,4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M50.1,17L50.1,17c2.8,0,5.1-2.3,5.1-5.1l0,0c0-2.8-2.3-5.1-5.1-5.1l0,0c-2.8,0-5.1,2.3-5.1,5.1l0,0C45,14.7,47.3,17,50.1,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M46.3,55.2h7.6l1.3-14l3.8-1.3V28.5c0-2.8-2.3-5.1-5.1-5.1h-7.6c-1.8,0-3.4,0.9-4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M31,18.3L31,18.3c-4.2,0-7.6-3.4-7.6-7.6l0,0C23.4,6.4,26.8,3,31,3l0,0c4.2,0,7.6,3.4,7.6,7.6l0,0C38.6,14.9,35.2,18.3,31,18.3z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M37.4,59H24.6l-1.3-15.3l-5.1-1.3V29.7c0-2.8,2.3-5.1,5.1-5.1h15.3c2.8,0,5.1,2.3,5.1,5.1v12.7l-5.1,1.3L37.4,59z"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  <div className="info">
                    <h4>Automative</h4>
                  </div>
                  <div>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        stroke="#fff"
                        fill="#fff"
                      >
                        <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="industry_cards">
                <img
                  src="https://motivoweb.com/saga/wp-content/uploads/2023/09/portfolio01.jpg"
                  alt=""
                />
                <div className=" perspective-caption   ">
                  <div className="icon-wrap">
                    {/* <img src={IMAGES.group} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      width="48"
                      x="0px"
                      y="0px"
                      viewBox="0 0 60 60"
                    >
                      {" "}
                      <g transform="translate(-1 -1)">
                        {" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M11.9,17L11.9,17c-2.8,0-5.1-2.3-5.1-5.1l0,0c0-2.8,2.3-5.1,5.1-5.1l0,0c2.8,0,5.1,2.3,5.1,5.1l0,0C17,14.7,14.7,17,11.9,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M15.7,55.2H8.1l-1.3-14L3,39.9V28.5c0-2.8,2.3-5.1,5.1-5.1h7.6c1.8,0,3.4,0.9,4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M50.1,17L50.1,17c2.8,0,5.1-2.3,5.1-5.1l0,0c0-2.8-2.3-5.1-5.1-5.1l0,0c-2.8,0-5.1,2.3-5.1,5.1l0,0C45,14.7,47.3,17,50.1,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M46.3,55.2h7.6l1.3-14l3.8-1.3V28.5c0-2.8-2.3-5.1-5.1-5.1h-7.6c-1.8,0-3.4,0.9-4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M31,18.3L31,18.3c-4.2,0-7.6-3.4-7.6-7.6l0,0C23.4,6.4,26.8,3,31,3l0,0c4.2,0,7.6,3.4,7.6,7.6l0,0C38.6,14.9,35.2,18.3,31,18.3z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M37.4,59H24.6l-1.3-15.3l-5.1-1.3V29.7c0-2.8,2.3-5.1,5.1-5.1h15.3c2.8,0,5.1,2.3,5.1,5.1v12.7l-5.1,1.3L37.4,59z"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  <div className="info">
                    <h4>Healthcare</h4>
                  </div>
                  <div>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        stroke="#fff"
                        fill="#fff"
                      >
                        <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="industry_cards">
                <img
                  src="https://motivoweb.com/saga/wp-content/uploads/2023/09/service22.jpg"
                  alt=""
                />
                <div className=" perspective-caption   ">
                  <div className="icon-wrap">
                    {/* <img src={IMAGES.group} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      width="48"
                      x="0px"
                      y="0px"
                      viewBox="0 0 60 60"
                    >
                      {" "}
                      <g transform="translate(-1 -1)">
                        {" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M11.9,17L11.9,17c-2.8,0-5.1-2.3-5.1-5.1l0,0c0-2.8,2.3-5.1,5.1-5.1l0,0c2.8,0,5.1,2.3,5.1,5.1l0,0C17,14.7,14.7,17,11.9,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M15.7,55.2H8.1l-1.3-14L3,39.9V28.5c0-2.8,2.3-5.1,5.1-5.1h7.6c1.8,0,3.4,0.9,4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M50.1,17L50.1,17c2.8,0,5.1-2.3,5.1-5.1l0,0c0-2.8-2.3-5.1-5.1-5.1l0,0c-2.8,0-5.1,2.3-5.1,5.1l0,0C45,14.7,47.3,17,50.1,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M46.3,55.2h7.6l1.3-14l3.8-1.3V28.5c0-2.8-2.3-5.1-5.1-5.1h-7.6c-1.8,0-3.4,0.9-4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M31,18.3L31,18.3c-4.2,0-7.6-3.4-7.6-7.6l0,0C23.4,6.4,26.8,3,31,3l0,0c4.2,0,7.6,3.4,7.6,7.6l0,0C38.6,14.9,35.2,18.3,31,18.3z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M37.4,59H24.6l-1.3-15.3l-5.1-1.3V29.7c0-2.8,2.3-5.1,5.1-5.1h15.3c2.8,0,5.1,2.3,5.1,5.1v12.7l-5.1,1.3L37.4,59z"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  <div className="info">
                    <h4>Engineering</h4>
                  </div>
                  <div>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        stroke="#fff"
                        fill="#fff"
                      >
                        <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="industry_cards">
                <img
                  src="https://motivoweb.com/saga/wp-content/uploads/2023/09/service22.jpg"
                  alt=""
                />
                <div className=" perspective-caption   ">
                  <div className="icon-wrap">
                    {/* <img src={IMAGES.group} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      width="48"
                      x="0px"
                      y="0px"
                      viewBox="0 0 60 60"
                    >
                      {" "}
                      <g transform="translate(-1 -1)">
                        {" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M11.9,17L11.9,17c-2.8,0-5.1-2.3-5.1-5.1l0,0c0-2.8,2.3-5.1,5.1-5.1l0,0c2.8,0,5.1,2.3,5.1,5.1l0,0C17,14.7,14.7,17,11.9,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M15.7,55.2H8.1l-1.3-14L3,39.9V28.5c0-2.8,2.3-5.1,5.1-5.1h7.6c1.8,0,3.4,0.9,4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M50.1,17L50.1,17c2.8,0,5.1-2.3,5.1-5.1l0,0c0-2.8-2.3-5.1-5.1-5.1l0,0c-2.8,0-5.1,2.3-5.1,5.1l0,0C45,14.7,47.3,17,50.1,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M46.3,55.2h7.6l1.3-14l3.8-1.3V28.5c0-2.8-2.3-5.1-5.1-5.1h-7.6c-1.8,0-3.4,0.9-4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M31,18.3L31,18.3c-4.2,0-7.6-3.4-7.6-7.6l0,0C23.4,6.4,26.8,3,31,3l0,0c4.2,0,7.6,3.4,7.6,7.6l0,0C38.6,14.9,35.2,18.3,31,18.3z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M37.4,59H24.6l-1.3-15.3l-5.1-1.3V29.7c0-2.8,2.3-5.1,5.1-5.1h15.3c2.8,0,5.1,2.3,5.1,5.1v12.7l-5.1,1.3L37.4,59z"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  <div className="info">
                    <h4>Automative</h4>
                  </div>
                  <div>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        stroke="#fff"
                        fill="#fff"
                      >
                        <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="industry_cards">
                <img
                  src="https://motivoweb.com/saga/wp-content/uploads/2023/09/portfolio01.jpg"
                  alt=""
                />
                <div className=" perspective-caption   ">
                  <div className="icon-wrap">
                    {/* <img src={IMAGES.group} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      width="48"
                      x="0px"
                      y="0px"
                      viewBox="0 0 60 60"
                    >
                      {" "}
                      <g transform="translate(-1 -1)">
                        {" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M11.9,17L11.9,17c-2.8,0-5.1-2.3-5.1-5.1l0,0c0-2.8,2.3-5.1,5.1-5.1l0,0c2.8,0,5.1,2.3,5.1,5.1l0,0C17,14.7,14.7,17,11.9,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M15.7,55.2H8.1l-1.3-14L3,39.9V28.5c0-2.8,2.3-5.1,5.1-5.1h7.6c1.8,0,3.4,0.9,4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M50.1,17L50.1,17c2.8,0,5.1-2.3,5.1-5.1l0,0c0-2.8-2.3-5.1-5.1-5.1l0,0c-2.8,0-5.1,2.3-5.1,5.1l0,0C45,14.7,47.3,17,50.1,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M46.3,55.2h7.6l1.3-14l3.8-1.3V28.5c0-2.8-2.3-5.1-5.1-5.1h-7.6c-1.8,0-3.4,0.9-4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M31,18.3L31,18.3c-4.2,0-7.6-3.4-7.6-7.6l0,0C23.4,6.4,26.8,3,31,3l0,0c4.2,0,7.6,3.4,7.6,7.6l0,0C38.6,14.9,35.2,18.3,31,18.3z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M37.4,59H24.6l-1.3-15.3l-5.1-1.3V29.7c0-2.8,2.3-5.1,5.1-5.1h15.3c2.8,0,5.1,2.3,5.1,5.1v12.7l-5.1,1.3L37.4,59z"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  <div className="info">
                    <h4>Healthcare</h4>
                  </div>
                  <div>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        stroke="#fff"
                        fill="#fff"
                      >
                        <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="industry_cards">
                <img
                  src="https://motivoweb.com/saga/wp-content/uploads/2023/09/service22.jpg"
                  alt=""
                />
                <div className=" perspective-caption   ">
                  <div className="icon-wrap">
                    {/* <img src={IMAGES.group} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      width="48"
                      x="0px"
                      y="0px"
                      viewBox="0 0 60 60"
                    >
                      {" "}
                      <g transform="translate(-1 -1)">
                        {" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M11.9,17L11.9,17c-2.8,0-5.1-2.3-5.1-5.1l0,0c0-2.8,2.3-5.1,5.1-5.1l0,0c2.8,0,5.1,2.3,5.1,5.1l0,0C17,14.7,14.7,17,11.9,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M15.7,55.2H8.1l-1.3-14L3,39.9V28.5c0-2.8,2.3-5.1,5.1-5.1h7.6c1.8,0,3.4,0.9,4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M50.1,17L50.1,17c2.8,0,5.1-2.3,5.1-5.1l0,0c0-2.8-2.3-5.1-5.1-5.1l0,0c-2.8,0-5.1,2.3-5.1,5.1l0,0C45,14.7,47.3,17,50.1,17z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          d="M46.3,55.2h7.6l1.3-14l3.8-1.3V28.5c0-2.8-2.3-5.1-5.1-5.1h-7.6c-1.8,0-3.4,0.9-4.4,2.5"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M31,18.3L31,18.3c-4.2,0-7.6-3.4-7.6-7.6l0,0C23.4,6.4,26.8,3,31,3l0,0c4.2,0,7.6,3.4,7.6,7.6l0,0C38.6,14.9,35.2,18.3,31,18.3z"
                        ></path>{" "}
                        <path
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-miterlimit="10"
                          d="M37.4,59H24.6l-1.3-15.3l-5.1-1.3V29.7c0-2.8,2.3-5.1,5.1-5.1h15.3c2.8,0,5.1,2.3,5.1,5.1v12.7l-5.1,1.3L37.4,59z"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  <div className="info">
                    <h4>Engineering</h4>
                  </div>
                  <div>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        stroke="#fff"
                        fill="#fff"
                      >
                        <path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Industries;
