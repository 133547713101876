import React, { useEffect, useState } from "react";
import { IMAGES } from "../utils";
import "../Assets/Styles/Header.css";
import { GrFacebookOption } from "react-icons/gr";
import { RiInstagramLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { CgMenuGridO } from "react-icons/cg";
import Accordion from "react-bootstrap/Accordion";
import { RiFacebookCircleLine } from "react-icons/ri";
import { Modal, Offcanvas } from "react-bootstrap";
import { RxInstagramLogo } from "react-icons/rx";
import { CiLinkedin, CiTwitter } from "react-icons/ci";
import { ImPinterest2 } from "react-icons/im";
import { RiYoutubeLine } from "react-icons/ri";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { TbBrandLinkedin } from "react-icons/tb";
const Header = () => {
  const navigate = useNavigate();
  const [mbViewShow, setmbViewShow] = useState(false);

  const handleShow = () => {
    setmbViewShow(true);
  };
  const handleClose = () => {
    setmbViewShow(false);
  };

  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 250) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  // Start Get Career Guidance
  const [GuidanceShow, setGuidanceShow] = useState(false);
  const GuidanceHandleShow = () => {
    setGuidanceShow(true);
    setmbViewShow(false);
  };

  // End Get Career Guidance
  // Start Get in touch
  const [inTouchShow, setInTouchShow] = useState(false);
  const GetHandleShow = () => {
    setInTouchShow(true);
    setmbViewShow(false);
  };
  // End Get in touch
  return (
    <>
      <div className="header-height">
        <div className="header">
          <div className="header-wrapper">
            <div className={`top-header ${show && "hidden"}`}>
              <div className="top-header-wrapper">
                <div className="top-header-left-wrapper">
                  <a href="mailto:info@aceiserv.com">
                    <p className="addmission-text">info@aceiserv.com</p>
                  </a>
                </div>
                <div className="top-header-right-wrapper">
                  <div className="d-flex header-time"></div>
                  <div className="d-flex header-mobile">
                    <a href="tel://+91 7030226677" className="d-flex">
                      <MdOutlinePhoneInTalk size={20} className="" />
                      <p>+91 7030226677</p>
                    </a>
                  </div>
                  <div className="d-flex top-social-icon">
                    <a href="#" target="_about">
                      <TbBrandLinkedin size={25} className="header-icon" />
                    </a>
                    <a href="#" target="_about">
                      <RiInstagramLine size={25} className="header-icon" />
                    </a>
                    <a href="#" target="_about">
                      {" "}
                      <GrFacebookOption size={25} className="header-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-header">
              <div className="header-logo-wrapper">
                <div className="logo-menu">
                  <Link to="/">
                    <img src={IMAGES.Logo} alt="" className="logo" />
                  </Link>
                  <div className="menu-icon">
                    <CgMenuGridO size={35} onClick={handleShow} />
                  </div>
                </div>
              </div>
              <div className="header-menu">
                <ul>
                  <li>
                    <Link to="/">
                      <p>HOME</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/aboutus">
                      <p>ABOUT US</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ServicesOfAceis">
                      <p>SERVICES</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p>INSIGHTS</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p>CAREERS</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Gallery1">
                      <p>GALLERY</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <p>CONTACT US</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="header-right-wrapper">
                <div className="blue-green-btn">
                  <button
                    onClick={GetHandleShow}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropTouch"
                  >
                    <span className="btn-text">Lets Talk</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Offcanvas */}

      <Offcanvas show={mbViewShow} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <img src={IMAGES.Logo} alt="" className="logo" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-social-icon-section">
            <ul>
              <li>
                <a href="#" target="_about">
                  <RiFacebookCircleLine
                    size={30}
                    className="header-icon facebook"
                  />
                </a>
              </li>
              <li>
                <a href="#" target="_about">
                  <RxInstagramLogo size={30} className="header-icon insta" />
                </a>
              </li>

              <li>
                <a href="#" target="_about">
                  <CiLinkedin size={30} className="header-icon linkedin" />
                </a>
              </li>
              <li>
                <a href="#" target="_about">
                  <ImPinterest2 size={30} className="header-icon pintrest" />
                </a>
              </li>
              <li>
                <a href="#" target="_about">
                  <RiYoutubeLine size={30} className="header-icon youtube" />
                </a>
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-start">
            <div className="mb-header-btn-wrapper">
              <button
                onClick={GetHandleShow}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropTouch"
              >
                Lets to Talk
              </button>
            </div>
          </div>

          <div className="mb-menu">
            <ul className="menu-content">
              <Link to="/" className="mb-header-link" onClick={handleClose}>
                HOME
              </Link>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    SERVICES
                    {/* <MdOutlineKeyboardArrowDown size={20} /> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      <Link to="#" onClick={handleClose}>
                        IT Service Automation
                      </Link>
                    </p>
                    <p>
                      <Link to="#" onClick={handleClose}>
                        ServiceNow Staffing Services
                      </Link>
                    </p>
                    <p>
                      <Link to="#" onClick={handleClose}>
                        Cybersecurity
                      </Link>
                    </p>
                    <p>
                      <Link to="#" onClick={handleClose}>
                        Salesforce
                      </Link>
                    </p>
                    <p>
                      <Link to="#" onClick={handleClose}>
                        Splunk
                      </Link>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Link
                to="/aboutus"
                onClick={handleClose}
                className="mb-header-link"
              >
                INSIGHTS
              </Link>
              <Link
                to="/aboutus"
                onClick={handleClose}
                className="mb-header-link"
              >
                CAREERS
              </Link>

              <Link
                to="/aboutus"
                onClick={handleClose}
                className="mb-header-link"
              >
                ABOUT US
              </Link>

              <Link
                to="/contactus"
                onClick={handleClose}
                className="mb-header-link"
              >
                CONTACT US
              </Link>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
