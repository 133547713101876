import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import { baseURL } from "./constant";
export const axiosInstance = axios.create({
  baseURL: baseURL,
});

//import { resolve } from "./resolve.js";
export default () => axios.get("/");

// //https://api.abhitalandsolutions.com
//http://localhost:5001
// //const API_URL="https://api.legavac.com/api/legavac";
const API_URL = "http://localhost:5000/api/aceisewebsite";

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

//contact
export async function iucontact(state) {
  return await axiosInstance
    .post(`${API_URL}/iucontact`, state)
    .then((res) => res.data);
}

export async function getContactDetail() {
  return await axiosInstance
    .get(`${API_URL}/getContactDetail`)
    .then((res) => res.data);
}

export async function getContactDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getContactDelete/${id}`)
    .then((res) => res.data);
}

export async function getContactDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getContactDetailByID/${id}`)
    .then((res) => res.data);
}

//Mail Sending hghg
export async function codehubiumail(state) {
  return await axiosInstance
    .post(`${API_URL}/codehubiumail`, state)
    .then((res) => res.data);
}
