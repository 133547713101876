// import React, { useState, useEffect } from "react";
import "../Assets/Styles/ItConsultancyServices.css";

import { IMAGES } from "../utils";

const Services = () => {
  return (
    <>
      <section>
        <div className="">
          <div className="itConsultancyService_wrapper">
            <div className="itservices">
              <div className="row">
                <div className=" col-lg-6 col-md-12 col-sm-12">
                  <div className="itService_inner">
                    <h3 className="heading-itservices">
                      IT Consultancy Services
                    </h3>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 ">
                  <div className="itService_inner">
                    <div>
                      <p className="para-itservices ">
                        Stop worrying about technology problems. Focus on your
                        business. Let us <br />
                        provide the support you deserve.
                      </p>
                      <a className="link--flip" aria-label="SPEAK TO AN EXPERT">
                        <hr />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom container">
            <div className="row">
              <div className="box col-lg-3 col-md-6 col-sm-12">
                <div className="front">
                  <img src={IMAGES.SalesforceConsulting} alt="" />
                  <h6>Salesforce Consulting</h6>
                </div>
                <div className="back">
                  <h4>Salesforce Consulting</h4>
                  <p>
                    Aceis consultants know how to make Salesforce increase your
                    customer acquisition, conversion and retention.
                  </p>
                </div>
              </div>
              <div className="box col-lg-3 col-md-6 col-sm-12">
                <div className="front">
                  <img src={IMAGES.SharePointConsulting} alt="" />
                  <h6>SharePoint Consulting</h6>
                </div>
                <div className="back">
                  <h4>SharePoint Consulting</h4>
                  <p>
                    Aceis offers SharePoint consulting to help orgs build a
                    powerful collaborative environment and cut operational
                    costs.
                  </p>
                </div>
              </div>
              <div className="box col-lg-3 col-md-6 col-sm-12">
                <div className="front">
                  <img src={IMAGES.SplunkConsulting} alt="" />
                  <h6>Splunk Consulting</h6>
                </div>
                <div className="back">
                  <h4>Splunk Consulting</h4>
                  <p>
                    We offer services, complementing the whole life cycle of
                    deploying and adopting the Splunk platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
