import React from "react";
import "../Assets/Styles/Offshore.css";
import { IMAGES } from "../utils";
import { FaAsterisk } from "react-icons/fa";

const Offshore = () => {
  return (
    <>
      <div className="offshareSection_wrapper">
        <div className="offshareSection_Slider">
          <div className="offshareSectionOverlay"></div>
          <div className="container">
            <h3>Offshore COE Execution</h3>
          </div>
        </div>

        <div className="offshareTextSection">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="offshareText">
                <p>
                  The biggest challenge for many organizations is access to
                  expertise. Every business wants to have efficient processes
                  that will lead to better services for customers, and hence
                  more revenue.
                </p>
                <p>
                  Aceis is an IT consulting company that helps businesses of all
                  sizes to set up remote teams. With a narrow focus on multiple
                  technology stacks. We established our own CoE to offer
                  business solutions by our experts
                </p>
                <p>
                  Partnering with an IT CoE uncovers many business benefits,
                  namely: highly experienced talent, advanced tech stack,
                  external worker management, and fast delivery of a quality
                  product.
                </p>
                <p>
                  If that’s what you are looking for, contact the Aceis CoE team
                  for high technology solutions for your project.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="offshareImg">
                <img src={IMAGES.offshores} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offshore;
