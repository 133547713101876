import React from "react";
import "../Assets/Styles/CyberSecurity.css";
import { IMAGES } from "../utils";
import { FaAsterisk } from "react-icons/fa";

const CyberSecurity = () => {
  return (
    <>
      <div className="cyberSLiderSection_wrapper">
        <div className="cyberSLiderSection">
          <div className="cyberSLiderSectionOverlay"></div>
          <div className="container">
            <h3>Cyber Security</h3>
          </div>
        </div>
        <div className="cyberSecurityTextSection">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="cyberSecurityText">
                <p>
                  Aceis as a Managed Security Systems Provider (MSSP)
                  understands the client’s crucial need for Managed Security
                  Systems and offers a wide range of security services that use
                  cutting-edge technologies. Our services help you manage
                  security systems effectively and protect your assets 24/7 from
                  rapidly ever-changing security threats.
                </p>
                <p>
                  {" "}
                  Aceis, as a trusted partner, is experienced in providing
                  personalized, simplified, and cost-saving security services to
                  clients. Our security operations use proven methodologies and
                  process which is delivered to you based on your specific
                  requirements. Below is the list of services provided for 360⁰
                  protection for your information assets.
                </p>
                <ul className="cyberSecurityTextUl">
                  <li>Perimeter Network Monitoring and Management</li>
                  <li>Security Information and Event Management</li>
                  <li>Identity Management Services</li>
                  <li>VPN Management</li>
                  <li>Managed Data Loss Prevention</li>
                  <li>Intrusion Prevention & Content filtering</li>
                  <li>
                    Security Governance for ISO 27001, GDPR, PCI, HIPAA, etc.
                  </li>
                  <li>Patch and Anti-virus Management</li>
                  <li>
                    Security Configuration Management & Hardening of IT Assets
                  </li>{" "}
                  <li>Information Security Incident Remediation Services</li>
                  <li>IT Security Dashboards</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="cyberSecurityImg">
                <img src={IMAGES.CyberSecurity1} />
              </div>
            </div>
          </div>
        </div>

        <div className="cyberSecuritySection2">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="cyberSecurityLeftImg">
                <img src={IMAGES.CyberSecurity2} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="cyberSecurityText">
                <h3 className="cyberSecurityHeading">
                  Our Strategic approaches
                </h3>
                <h3 className="cyberSecuritySubHeading">
                  to secure your IT infrastructure are:
                </h3>

                <ul className="cyberSecurityTextUl">
                  <li>
                    <b>Assess: </b>Analyze and document the security needs based
                    on your business operation.
                  </li>
                  <li>
                    <b>Design: </b>Draft policies and design strategies and
                    frameworks as per the documented requirements.
                  </li>
                  <li>
                    <b>Build: </b> Procure and deploy the latest tools,
                    software, and equipment.
                  </li>
                  <li>
                    <b>Comply: </b>Ensure compliance with the regulatory
                    requirements.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CyberSecurity;
