import React from "react";
import "../Assets/Styles/AIWorkflow.css";
import { IMAGES } from "../utils";
import { FaAsterisk } from "react-icons/fa";

const AIWorkflow = () => {
  return (
    <>
      <div className="webdev-wrapper">
        <div className="wat-is-web-dev-wrapper">
          <div className="container">
            <div className="wat-is-web-dev">
              <h3>Servicely - AI Workflows</h3>
              <p>World’s first Intelligent IT Service Management Platform</p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
            </div>
          </div>
        </div>

        <div className="wat-webdev-do-wrapper">
          <div className="wat-web-dev-do-wrapper">
            <div className="row AIWorkFlowRow">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="what-is-web-dev-do-wrapper">
                  <div className="wat-is-web-dev-do">
                    <h3>Proud solutions partner for Servicely</h3>
                    <p>
                      Being the only partner company of Servicely in India, we
                      are dedicated to offering you the most innovative and
                      efficient services to help manage your business
                      operations.
                    </p>
                    <p>
                      Our AI platform leverages cutting-edge technology to
                      provide you with a comprehensive set of tools to
                      streamline your service management processes, making them
                      simpler and more effective. Our solution is customizable,
                      which means that you can adapt it to your specific
                      industry needs and requirements.
                    </p>
                    <p>
                      We know that your business success is your top priority,
                      and that is why we are committed to helping you achieve
                      it. Our AI service management platform provides you with
                      real-time insights and analytics to help you make
                      data-driven decisions that will give you a competitive
                      edge in your industry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  src={IMAGES.ServicelyAI1}
                  alt="WebDevImage"
                  className="what-devv-ddo-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="wat-web-dev-do-wrapper">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  src={IMAGES.ServicelyAI2}
                  alt="WebDevImage"
                  className="what-devv-ddo-img"
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="what-is-web-dev-do-wrapper">
                  <div className="wat-is-web-dev-do">
                    <p>
                      Our platform is user-friendly, reliable, and
                      cost-effective. We pride ourselves on our customer service
                      and support, and we are always here to help you whenever
                      you need us.
                    </p>
                    <p>
                      Join our growing community of satisfied customers from
                      diverse industries, including healthcare, hospitality,
                      retail, and more. Whether you’re a small startup or a
                      large enterprise, we’ve got you covered.
                    </p>
                    <p>
                      Let us help you streamline your service management
                      processes and take your business to the next level.
                      Contact us today to learn more about our AI service
                      management platform and how we can help you achieve your
                      business goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIWorkflow;
