import React, { useState, useEffect } from "react";
import "../Assets/Styles/salesforceconsulting.css";
import { IMAGES } from "../utils";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

const SaleforceConsultancy = () => {
  return (
    <>
      <div>
        <div className="saleForceSliderOuter">
          <div className="saleForceOverlay"></div>
          <div className="container">
            <h3>Salesforce Consulting</h3>
          </div>
        </div>
        <div className="saleForceDescriptionOuter">
          <div className="saleForceDescription">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="saleforceDescPara">
                  <p>
                    We will assist you in finding the most appropriate
                    Salesforce Cloud, Service Cloud, Health Cloud, Community
                    Cloud, CPQ to suit your needs and help you set up, configure
                    and customise all the required Salesforce clouds to meet
                    your business needs. Our experts will use tailor-made email
                    templates, custom workflows, and security access and
                    approval processes to implement process automation. Besides
                    this, they help your business attain necessary data
                    visibility and insights that can aid in making better
                    business decisions by incorporating custom reports and
                    dashboards.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="saleforceDescImg">
                  <img src={IMAGES.salforce1} />
                </div>
              </div>
            </div>
          </div>
          <section className="ExpertisesSetion">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="Expertise-img">
                    <img src={IMAGES.salforce2} className="" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="ExpertiseTitle">
                    <h3>Our Expertise</h3>
                  </div>

                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {" "}
                        <p className="expertiseAccordianHeading">
                          {" "}
                          SALES CLOUD
                        </p>
                      </Accordion.Header>
                      <Accordion.Body className="expertiseBody">
                        Sales Cloud, a fully customizable product powered by the
                        world’s #1 CRM solution Provider Salesforce, salesforce
                        Sales Cloud is the world’s leading Sales automation tool
                        that helps Sales Managers and Reps work smarter by
                        automating sales processes, enhancing efficiency,
                        accelerating sales productivity, creating a pipeline of
                        leads, closing deals faster and making data-driven
                        insightful decisions. The platform is provided as SaaS
                        (Software as a Service) for browser-based access even
                        available in the form of a mobile app.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {" "}
                        <p className="expertiseAccordianHeading">
                          SERVICE CLOUD
                        </p>
                      </Accordion.Header>
                      <Accordion.Body className="expertiseBody">
                        Salesforce Service Cloud is a customer relationship
                        management (CRM) platform specifically designed for
                        customer service and support. This tool is based on the
                        company’s CRM software exclusive for Sales
                        Professionals. This customer service and support
                        platform allows users to automate service processes,
                        streamline workflows and identify crucial articles,
                        topics, and experts in order to support the agent. With
                        the ultimate objective to foster a one-to-one marketing
                        relationship with each and every customer across
                        multiple channels and on any device.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        {" "}
                        <p className="expertiseAccordianHeading">
                          Market CLOUD
                        </p>
                      </Accordion.Header>
                      <Accordion.Body className="expertiseBody">
                        From 360-degree customer view to microscopic reporting &
                        tracking, and from communicating with customers across
                        multiple channels to seamless campaign management, we
                        help businesses leverage every bit of this platform and
                        forge the strongest customer relationship with our
                        best-in-class Salesforce marketing cloud services.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        {" "}
                        <p className="expertiseAccordianHeading">
                          Health CLOUD
                        </p>
                      </Accordion.Header>
                      <Accordion.Body className="expertiseBody">
                        It helps you take the best care for your patients by
                        letting you have a complete database with every relevant
                        detail pertaining to your patient on a centralized
                        platform. We help you implement Salesforce Health Cloud
                        according to your specific requirements and integrate
                        the same with other platforms (HIS, Service Cloud, ERP,
                        CTI, Marketing Cloud, Patient App, EMR etc) to make sure
                        that your patients get the highest quality of healthcare
                        services from you.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default SaleforceConsultancy;
