import React from "react";
import "../Assets/Styles/HowWeWork.css";
import { IMAGES } from "../utils";
import { AnimationOnScroll } from "react-animation-on-scroll";
const pStyle = {
  color: "#676c71",
  fontSize: "15px",
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
  fontWeight: "600",
  letterSpacing: "0.4px",
};
const HowWeWorks = () => {
  return (
    <>
      <section className="howWork-section">
        <div className="howWork-inner-section">
          <div className="howWork-title-wrapper">
            <div className="howWork-title">
              <span className="border-left"></span>
              <p>The Steps</p>
              <span className="border-right"></span>
              <AnimationOnScroll animateIn="animate__zoomIn">
                <h3 className="howWork-sub-title">
                  Schedule your personalized consultation today
                </h3>
                
              </AnimationOnScroll>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={100}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.groupdiscussion}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>
                      <div className="angle-icon">
                        <img src={IMAGES.HowWorkArrow} />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Identifying the Needs</h5>
                      <p>
                        Holds in these matters principles all selection right
                        rejects.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={300}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.planning}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>{" "}
                      <div className="angle-icon">
                        <img src={IMAGES.HowWorkArrow} />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Preparing a Job Description</h5>
                      <p>
                        Frequently occur that’s pleasures in have to repudiated.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={500}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.Code}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>{" "}
                      <div className="angle-icon">
                        <img src={IMAGES.HowWorkArrow} />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Find a Talented Candidate</h5>
                      <p>
                        Fault with man who choose enjoy a annoying consequences.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <AnimationOnScroll animateIn="animate__fadeInLeft" delay={700}>
                <div className="howWork-wrapper">
                  <div className="howWork-Wrapper-inner">
                    <div className="icon-outer-section">
                      <div className="icon-bg">
                        <img
                          src={IMAGES.launch}
                          alt=""
                          className="howWork-icon"
                        />
                      </div>
                    </div>
                    <div className="howWok-main-title">
                      <h5>Screening and Shortlisting</h5>
                      <p>
                        Holds in these matters principles all selection right
                        rejects.
                      </p>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowWeWorks;
