import AIWorkflow from "../Pages/AIWorkflow";

const IMAGES = {
  RoundAbstract: require("../Assets/Images/abstract-4.png"),

  Profile: require("../Assets/Images/Profile.png"),
  Logo: require("../Assets/Images/AceisWeblogo.png"),
  bgRequestcallBack: require("../Assets/Images/bgRequestcallBack.jpg"),
  BG1: require("../Assets/Images/bg-1.png"),
  BG2: require("../Assets/Images/bg-2.png"),
  FAQs: require("../Assets/Images/faqhome.jpeg"),
  Img1: require("../Assets/Images/img-1.jpg"),
  Img2: require("../Assets/Images/img-2.jpg"),
  Shape12: require("../Assets/Images/shape-12.png"),
  ServicesSideImg: require("../Assets/Images/Aceis/ai.jpg"),
  staffingai: require("../Assets/Images/Aceis/staffing-ai.jpg"),

  groupdiscussion: require("../Assets/Images/Aceis/groupdiscussion.png"),
  Code: require("../Assets/Images/Aceis/code.png"),
  launch: require("../Assets/Images/Aceis/launch.png"),
  planning: require("../Assets/Images/Aceis/planning.png"),

  //testimonials
  TestimonialRight: require("../Assets/Images/TestimonialRight.jpg"),

  Shape20: require("../Assets/Images/shape-20.png"),
  ceo: require("../Assets/Images/Aceis/ceo.jpg"),
  ItConsultancy: require("../Assets/Images/Aceis/ItConsultancyService.jpg"),
  cybersecurity: require("../Assets/Images/Aceis/cybersecurity.jpg"),
  staffing: require("../Assets/Images/Aceis/staffing.jpg"),
  stratehicstaffing: require("../Assets/Images/Aceis/stratehicstaffing.jpg"),
  // Home Page Banner
  AceisSlider: require("../Assets/Images/Aceis/slider-1.jpg"),
  HomeGraphicsPage: require("../Assets/Images/HomeBanner/GraphicsDesign.png"),
  HomeGraphicsBGPage: require("../Assets/Images/HomeBanner/GraphicsDesignBg.png"),
  HomeDigitalPage: require("../Assets/Images/HomeBanner/DigitalMarketing.png"),

  Servicebg: require("../Assets/Images/service-img.jpg"),
  aboutUsSection2: require("../Assets/Images/aboutUsSection2.jpg"),
  aboutbg: require("../Assets/Images/aboutbg.jpg"),
  aboutUsSection1: require("../Assets/Images/aboutUsSection1.jpg"),
  DMLandingImage: require("../Assets/Images/Digitalmarketing/landingImage.avif"),
  DM7: require("../Assets/Images/Digitalmarketing/DM7.avif"),
  DM8: require("../Assets/Images/Digitalmarketing/DM8.png"),
  whatIsDm: require("../Assets/Images/Digitalmarketing/whatIsDm.jpg"),
  aboutUsSlider: require("../Assets/Images/Digitalmarketing/aboutSlide.jpg"),
  missionVission: require("../Assets/Images/mission.png"),

  // module

  module_1: require("../Assets/Images/Digitalmarketing/module_1.jpg"),
  syllabus_bg: require("../Assets/Images/Digitalmarketing/syllabus/syllabus_bg.jpg"),
  a1: require("../Assets/Images/Digitalmarketing/a1.jpg"),
  a2: require("../Assets/Images/Digitalmarketing/a2.jpg"),
  a3: require("../Assets/Images/Digitalmarketing/a3.jpg"),

  // Graphics

  bestGd1: require("../Assets/Images/GraphicsDesign/bestgd_1.webp"),
  bestGd2: require("../Assets/Images/GraphicsDesign/bestgd_2.webp"),
  whatIsGd: require("../Assets/Images/GraphicsDesign/whatIsGd.jpg"),
  whyGd: require("../Assets/Images/GraphicsDesign/whyGd.jpg"),
  faqGd: require("../Assets/Images/GraphicsDesign/FAQ.png"),

  //service
  ServicesWebDesign: require("../Assets/Images/Services/website-design.avif"),
  ServicesDM: require("../Assets/Images/Services/DigitalMarketing.jpg"),
  ServicesGD: require("../Assets/Images/Services/graphics-Design.avif"),
  ServicesITTraining: require("../Assets/Images/Services/it-traing.avif"),
  WebsiteDev: require("../Assets/Images/Services/WebsiteDevelopment.jpg"),

  WebDesignIcon1: require("../Assets/Images/Services/icon1.png"),
  WebDesignIcon2: require("../Assets/Images/Services/icon2.png"),
  Marquee1: require("../Assets/Images/Services/marquee-website.jpg"),
  WebDesignSolution: require("../Assets/Images/Services/WebDesignSolutions.jpg"),

  ResponsiveWebDesign: require("../Assets/Images/Services/ResponsiveWebDesign.jpg"),
  DesignExperience: require("../Assets/Images/Services/DesignExperience.jpg"),
  Brand: require("../Assets/Images/Services/Brand.jpg"),

  UserExp: require("../Assets/Images/Services/UserExperience.jpg"),
  TechExpertise: require("../Assets/Images/Services/TechnicalExpertise.jpg"),
  MobileFriendly: require("../Assets/Images/Services/MobileFriendly.jpg"),

  DMAudit: require("../Assets/Images/Services/Audit.jpg"),
  DMSEOStrategy: require("../Assets/Images/Services/SEOStrategy.jpg"),
  DMOnSiteSEO: require("../Assets/Images/Services/On-siteSEO.jpg"),
  DMImgWorking: require("../Assets/Images/Services/working-progress.png"),

  DMHappyCustomer: require("../Assets/Images/Services/HappyCustomer.png"),
  DMExpertAdvisor: require("../Assets/Images/Services/ExpertAdvisor.png"),
  DMProjectCompleted: require("../Assets/Images/Services/ProjectCompleted.png"),

  DMPartnership: require("../Assets/Images/Services/partnership.png"),
  DMInnovation: require("../Assets/Images/Services/innovation.png"),
  DMStratergy: require("../Assets/Images/Services/Market-Strategy.png"),
  DMFunction: require("../Assets/Images/Services/support-function.png"),
  DMResearch: require("../Assets/Images/Services/Marketing-research.png"),
  DMLead: require("../Assets/Images/Services/lead.png"),
  DMImgAbout: require("../Assets/Images/Services/dm-about.png"),

  GDBanner: require("../Assets/Images/Services/GraphicDesign/GraphicsDesignBanner.jpg"),
  GDPrintDesignGreen: require("../Assets/Images/Services/GraphicDesign/print-design-green.jpg"),
  GDPrintDesignPurple: require("../Assets/Images/Services/GraphicDesign/print-design-purple.jpg"),
  GDBrand1: require("../Assets/Images/Services/GraphicDesign/Mockup1.jpg"),
  GDBrand2: require("../Assets/Images/Services/GraphicDesign/Mockup2.jpg"),
  GDBrand3: require("../Assets/Images/Services/GraphicDesign/Mockup3.jpg"),
  GDBrand4: require("../Assets/Images/Services/GraphicDesign/Mockup4.jpg"),
  GDMan: require("../Assets/Images/Services/GraphicDesign/home-cybersecurity-contact-bg-image.png"),
  GDVisitingCard: require("../Assets/Images/Services/GraphicDesign/visiting-card.png"),
  GDBrochure: require("../Assets/Images/Services/GraphicDesign/brochure.png"),
  GDCatalogue: require("../Assets/Images/Services/GraphicDesign/catalogue.png"),
  GDCorporateIdentity: require("../Assets/Images/Services/GraphicDesign/brand-identity.png"),
  GDInvititionCard: require("../Assets/Images/Services/GraphicDesign/invitation.png"),
  GDAdvertisement: require("../Assets/Images/Services/GraphicDesign/advertising.png"),
  GDNewsletter: require("../Assets/Images/Services/GraphicDesign/information.png"),
  GDGreetings: require("../Assets/Images/Services/GraphicDesign/greeting-card.png"),
  GDBook: require("../Assets/Images/Services/GraphicDesign/book.png"),
  Abstract1: require("../Assets/Images/Services/abstract-2.png"),
  Abstract2: require("../Assets/Images/Services/abstract-3.png"),

  WebDesign2: require("../Assets/Images/web-design2.png"),
  WebDev2: require("../Assets/Images/web-development2.png"),
  Graphic2: require("../Assets/Images/graphic-designing2.png"),
  Digital2: require("../Assets/Images/digital-marketing2.png"),
  ContactLady: require("../Assets/Images/contact-lady.png"),

  // Our Courses Section
  CSWebDesign: require("../Assets/Images/OurCourses(Home)/Web Design.jpg"),
  CSWebDev: require("../Assets/Images/OurCourses(Home)/WebDevelopment.jpg"),
  CSFullStack: require("../Assets/Images/OurCourses(Home)/FullStack.jpg"),
  CSDigital: require("../Assets/Images/OurCourses(Home)/DigitalMarketing.jpg"),
  CSGraphics: require("../Assets/Images/OurCourses(Home)/GraphicDesigner.jpg"),
  CSPython: require("../Assets/Images/OurCourses(Home)/Python.jpg"),
  CSPowerBi: require("../Assets/Images/OurCourses(Home)/PowerBI.jpg"),
  CSBA: require("../Assets/Images/OurCourses(Home)/BusinessAnalyst.jpg"),
  CSSoftTesting: require("../Assets/Images/OurCourses(Home)/SoftwareTesting.jpg"),
  CSAptitute: require("../Assets/Images/OurCourses(Home)/AptitudeCourses.jpg"),

  // Logos
  DreamEstate: require("../Assets/Images/OurPartners/DreamEstateLogo.png"),
  AbhitaLand: require("../Assets/Images/OurPartners/abhitaland.png"),
  AbhitaFinance: require("../Assets/Images/OurPartners/AbhitaFinance.png"),
  Legavac: require("../Assets/Images/OurPartners/logolegavac.png"),
  Cowork: require("../Assets/Images/OurPartners/cowork.png"),
  Year99: require("../Assets/Images/OurPartners/99YearsLogo.png"),
  Bsideu: require("../Assets/Images/OurPartners/Bsideu.png"),
  CityBank: require("../Assets/Images/OurPartners/CityBankLogo.png"),
  OBC: require("../Assets/Images/OurPartners/OBC.png"),
  SahuBankGramin: require("../Assets/Images/OurPartners/SahuBankGramin.png"),
  SahuBankMulti: require("../Assets/Images/OurPartners/SahuBankMulti.png"),

  // Why DM
  IncreasedReach: require("../Assets/Images/WhyDM/IncreasedReach.jpg"),
  GreaterTargeting: require("../Assets/Images/WhyDM/GreaterTargeting.jpg"),
  CostEffective: require("../Assets/Images/WhyDM/Cost-Effective.jpg"),
  MeasurableResults: require("../Assets/Images/WhyDM/MeasurableResults.jpg"),
  FlexibilityAndAgility: require("../Assets/Images/WhyDM/FlexibilityAndAgility.jpg"),

  //Why Graphics Design?
  AIWorkflow: require("../Assets/Images/Aceis/AI-Workflows.jpg"),
  CyberSecurities: require("../Assets/Images/Aceis/Cyber-Securities.jpg"),
  InConsultancy: require("../Assets/Images/Aceis/IT-Cunsulting-Services.jpg"),
  CoeExecution: require("../Assets/Images/Aceis/Offshore-COE-Execution.jpg"),
  staffing: require("../Assets/Images/Aceis/Strategic-Staffing.jpg"),

  //Software Used In Graphics Design
  Ps: require("../Assets/Images/GraphicsDesign/PS.png"),
  Ai: require("../Assets/Images/GraphicsDesign/AI.png"),
  Pr: require("../Assets/Images/GraphicsDesign/PR.png"),
  Ae: require("../Assets/Images/GraphicsDesign/AE.png"),
  Coral: require("../Assets/Images/GraphicsDesign/Corel.png"),

  //blogs
  Compition: require("../Assets/Images/Blog/compition.jpg"),
  BlogInnerDM: require("../Assets/Images/Blog/DigitalMarketing.jpg"),

  // webdev
  watWebDevDo: require("../Assets/Images/web-dev-do.png"),

  //HowWeWorks
  HowWorkDiscuss: require("../Assets/Images/HowWeWorks/groupdiscussion.png"),
  HowWorkArrow: require("../Assets/Images/HowWeWorks/arrow.png"),
  HowWorkIdea: require("../Assets/Images/HowWeWorks/planning-svgrepo-com (2).png"),
  HowWorkCode: require("../Assets/Images/HowWeWorks/code.png"),
  HowWorkLaunch: require("../Assets/Images/HowWeWorks/launch.png"),
  //footer brand logo
  Sutrahr: require("../Assets/Images/Brand/Bigthings.png"),
  timeandwatch: require("../Assets/Images/Brand/dozeworks.png"),
  staffcare: require("../Assets/Images/Brand/ilinkdigital.jpg"),
  umbrella: require("../Assets/Images/Brand/neomedic.png"),
  hopin: require("../Assets/Images/Brand/oppchain.png"),
  Nextwave: require("../Assets/Images/Brand/servicely.png"),

  //IndustriesSupport
  group: require("../Assets/Images/group.png"),
  //it
  SalesforceConsulting: require("../Assets/Images/IT/1.jpg"),
  SharePointConsulting: require("../Assets/Images/IT/2.jpg"),
  SplunkConsulting: require("../Assets/Images/IT/3.jpg"),

  ServicelyAI1: require("../Assets/Images/Aceis/ServicelyAI.jpg"),
  ServicelyAI2: require("../Assets/Images/Aceis/ServicelyAI_2.jpg"),
  CyberSecuritySlider: require("../Assets/Images/Aceis/cybersecurity.jpg"),
  CyberSecurity1: require("../Assets/Images/Aceis/CyberSecurity1.jpg"),
  CyberSecurity2: require("../Assets/Images/Aceis/cyberSecurity2.jpg"),
  offshores: require("../Assets/Images/Aceis/offshoresRightImg.jpg"),

  salforce1: require("../Assets/Images/Aceis/saleForce1.jpg"),
  salforce2: require("../Assets/Images/Aceis/saleForce2.png"),
  salforce3: require("../Assets/Images/Aceis/saleForce3.jpg"),
};
export { IMAGES };
