import React, { useState, useEffect } from "react";
import "../Assets/Styles/ItService.css";
import { IMAGES } from "../utils";
import { Link } from "react-router-dom";

const ItServices = () => {
  return (
    <>
      <div>
        <div className="itServiceSliderOuter">
          <div className="itServiceOverlay"></div>
          <div className="container">
            <h3>IT Consultancy Services</h3>
          </div>
        </div>
        <div className="container">
          <div className="consulationCardOuter">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="consulationCardinner">
                  <a href="/salesforceconsulting">
                    <div className="itConsultancyImg">
                      <img src={IMAGES.SalesforceConsulting} />
                      <div className="itConsultancyTitle">
                        <p className="itConsultancyDescription">
                          Aceis consultants know how to make Salesforce increase
                          your customer acquisition, conversion and retention.
                        </p>
                        <div className="itConsultancySeperator">
                          <span></span>
                        </div>
                        <h3>Sales force Consulting</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="consulationCardinner">
                  <a href="/salesforceconsulting">
                    <div className="itConsultancyImg">
                      <img src={IMAGES.SharePointConsulting} />
                      <div className="itConsultancyTitle">
                        <p className="itConsultancyDescription">
                          Aceis offers SharePoint consulting to help orgs build
                          a powerful collaborative environment and cut
                          operational costs.
                        </p>
                        <div className="itConsultancySeperator">
                          <span></span>
                        </div>
                        <h3>Sales force Consulting</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="consulationCardinner">
                  <a href="/salesforceconsulting">
                    <div className="itConsultancyImg">
                      <img src={IMAGES.SplunkConsulting} />
                      <div className="itConsultancyTitle">
                        <p className="itConsultancyDescription">
                          We offer services, complementing the whole life cycle
                          of deploying and adopting the Splunk platform.
                        </p>
                        <div className="itConsultancySeperator">
                          <span></span>
                        </div>
                        <h3>Sales force Consulting</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItServices;
