import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { IMAGES } from "../utils/index";

import "../Assets/Styles/Media.css";
import { MdClose } from "react-icons/md";

const imgUrls = [
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
  { img: IMAGES.IncreasedReach },
];

const Gallery1 = () => {
  return (
    <>
      <div className="media-section">
        <section className="media-tab-bg">
          <div className="media-bg-text">
            <h3>Media</h3>
          </div>
        </section>
        <div className="media-wrapper">
          <h3 className="media-heading">Our Stories</h3>
          <div className="container">
            <div>
              <div className="row">
                <Gallery>
                  {imgUrls.map((item, index) => (
                    <div className="col-xl-3 col-lg-4 col-md-6  col-sm-12">
                      <Item
                        original={item.img}
                        thumbnail={item.img}
                        width="800"
                        height="600"
                      >
                        {({ ref, open }) => (
                          <div className="media-img-col-wrapper">
                            <div className="media-img-col">
                              <img
                                ref={ref}
                                onClick={open}
                                src={item.img}
                                alt={`Image ${index + 1}`}
                              />
                            </div>
                          </div>
                        )}
                      </Item>
                    </div>
                  ))}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery1;
